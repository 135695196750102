import { watch } from 'fs';

const Container = () => import('../views/Container');

const ReportGeneratorPage = () => import('../views/ReportGeneratorPage');
const ProductsInStock = () => import('../components/ProductsInStock');
const InwardOutwardStock = () => import('../components/InwardOutwardStock');
const clientsReports = () => import('../components/clientsReports');
const clientsBirthDayReports = () =>
  import('../components/clientsBirthDayReports.vue');
const ClientsBuyersReports = () =>
  import('../components/ClientsBuyersReports.vue');
const BestSellingProductsReport = () =>
  import('../components/BestSellingProductsReport.vue');
const ClientsDeptGroupReport = () =>
  import('../components/ClientsDeptGroupReport.vue');
const UnsoldPorducts = () => import('../components/UnsoldProducts.vue');
const abcCurveReportProducts = () =>
  import('../components/abcCurveReportProducts.vue');
const abcCurveClientsReport = () =>
  import('../components/abcCurveClientsReport.vue');
const SalesPerClient = () => import('../components/SalesPerClient.vue');
const IndividualProductMovement = () =>
  import('../components/IndividualProductMovement');
const ProductsProfit = () => import('../components/ProductsProfit');
const ProductsPerClient = () => import('../components/ClientOrderProductsReport.vue')

export default [
  {
    path: '/relatorios',
    component: Container,
    meta: { requiresAuth: false },
    children: [
      {
        path: '',
        name: 'report-generator',
        component: ReportGeneratorPage,
      },
      {
        path: '/relatorios/entrada-saida-estoque',
        name: 'inward-outward-stock',
        component: InwardOutwardStock,
      },
      {
        path: '/relatorios/produtos-estoque',
        name: 'products-in-stock',
        component: ProductsInStock,
      },
      {
        path: '/relatorios/movimentacao-produtos',
        name: 'individual-product-Movement',
        component: IndividualProductMovement,
      },
      {
        path: '/relatorios/lucro-de-produtos',
        name: 'products-profit',
        component: ProductsProfit,
      },
      {
        path: '/relatorios/relatorio-de-clientes',
        name: 'clients-reports',
        component: clientsReports,
      },
      {
        path: '/relatorios/relatorios-aniversarios-clientes',
        name: 'clients-birthday-reports',
        component: clientsBirthDayReports,
      },
      {
        path: '/relatorios/relatorios-clientes-compradores',
        name: 'clients-buyers-reports',
        component: ClientsBuyersReports,
      },
      {
        path: '/relatorios/produtos-mais-ou-menos-vendidos',
        name: 'best-selling-products',
        component: BestSellingProductsReport,
      },
      {
        path: '/relatorios/relatorios-debitos-clientes',
        name: 'customer-debt-group',
        component: ClientsDeptGroupReport,
      },
      {
        path: '/relatorios/produtos-sem-vendas',
        name: 'unsold-products',
        component: UnsoldPorducts,
      },
      {
        path: '/relatorios/curva-ABC-produtos',
        name: 'abc-curve-products',
        component: abcCurveReportProducts,
      },
      {
        path: '/relatorios/curva-ABC-clientes',
        name: 'abc-curve-clients',
        component: abcCurveClientsReport,
      },
      {
        path: '/relatorios/vendas-por-cliente',
        name: 'sales-per-client',
        component: SalesPerClient,
      },
      {
        path: '/relatorios/produtos-por-cliente',
        name: 'products-per-client',
        component: ProductsPerClient,
      },
    ],
  },
];
